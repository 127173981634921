<template>

    <v-card flat>
        <v-card-text>
            <div class="form-box-title title-with-right-part alm-title">
                <span>{{$t("Вложения")}}</span>
                <div class="twrp-content">
                    <v-chip 
                        
                        class="table-label"
                        small
                        label
                        color="teal"
                        dark
                        @click="loadAllAttachments"
                        v-if="groupedAttachments.length"
                    >
                        <div class="d-flex align-center">
                            <v-icon>fas fa-file-download</v-icon>
                            {{ $t("Скачать_все") }}
                        </div>
                    </v-chip>
                </div>
            </div>          

            <v-row class="full-width-row" no-gutters>
                <v-col cols="12" sm="12" md="2">
                    <label class="f-label">{{$t("Файлы_вложений")}}</label>
                </v-col>
                <v-col cols="12" sm="12" md="10">
                    <div class="cloud-file-list-box">
                        <div class="cloud-items-container">

                            <div v-if="!groupedAttachments.length" class="cfl-placeholder" >{{$t('Нет_файлов')}}</div>

                            <v-row 
                                v-else
                                dense
                            >
                                <v-attachment-item
                                    v-for="attachment in groupedAttachments[0][1]"
                                    :key = "attachment.id"
                                    :attachment="attachment"
                                    :onDeleteAttachment="deleteAttachment"   
                                    :onLoadAttachmet="loadAttachment"
                                    :onAttachmentInfo="openInfoModal"
                                    :isDeleteable="!attachment.IsReadOnly && ( !isDocument || isExistedDocument ) && !isDocumentIsArchive"
                                />
                            </v-row>

                        </div>
                    </div>
                </v-col>
            </v-row>

        </v-card-text>
        <FileInfoDlg ref="FileInfoDlgRef" />        
    </v-card>

</template>

<script>
import sys from '@/services/system'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FileInfoDlg from '@/components/global/dialogs/FileInfoDlg';
import { httpAPI, errorHandler } from "@/api/httpAPI";

export default
{
    name: 'AttachmentsPanel',
    components: {
        FileInfoDlg      
    },
    data()
    {
        return {}
    },
    computed:
    {
        ...mapGetters('global/actionsource', { documentSource: 'getDataSource', isDocumentIsArchive: 'isDocumentIsArchive', dataSourceType: 'getDataSourceType', documentId: 'getDataSourceDocumentId' }),
        ...mapGetters('global/attachments', { dataSource: 'getDataSource', previewSrc: 'getPreviewSrc' }),
        groupedAttachments() {
            let groupedAttachments = this.$_.groupBy(this.dataSource, (item) => {
                return item.Group;
            });
            
            Object.keys(groupedAttachments).forEach((key) => {            
                groupedAttachments[key] = groupedAttachments[key].map(x => x).sort(function (a, b) { return a.Name.localeCompare(b.Name); });
            });

            return Object.entries(groupedAttachments);
        },
        isDownloadAllVisible() {
            if (this.dataSourceType === 'ReportQuery'){
                return this.dataSource.filter(item => item.Id != this.documentSource.Data.Object.Document.SchemaAttachmentId && item.Id != this.documentSource.Data.Object.Document.TemplateAttachmentId).length > 1;
            }
            else 
                return this.dataSource?.length > 1;
        },
        isDocument() {
            return this.documentSource?.Data?.Object?.Document != null;
        },
        isExistedDocument() {
            return this.documentSource?.Data?.Object?.Document?.is_new_record === false;
        },
    },
    methods:{
        ...mapActions('global/attachments', ['setCurrentDocumentId', 'updateDataSource', 'deleteAttachment', 'setPreviewSrc', 'setDataSource']),
        ...mapMutations('global/attachments', { setDataSource: 'SET_DATASOURCE' }),
        formatDate: sys.dateFormat,
        async update() {
            this.setDataSource({ dataSource: [] });
            await this.setCurrentDocumentId(this.documentId);
            await this.updateDataSource();
        },
        async openInfoModal(attachment) {
            try
            {
                await this.$refs.FileInfoDlgRef.open({ ParentType: this.$store.getters['actionsource/getDataSourceType'], ...attachment });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async loadAttachment(item) {
            this.$eventBus.$emit('on-download-file', { id: item.Id, name: item.Name, });
            /*
            // формируем URL
            let url = `api/download/${this.dataSourceType === 'Meeting' ? 'meeting': ''}attachment?id=${item.Id}&name=${item.Name}&mode=${process.env.NODE_ENV}&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}`;
            
            //Для отображения прогресса скачивания вложения
            if (process.env.NODE_ENV === "production") {
                // этот механизм будет работать если хост VUE app и хост API одинаковый
                url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                const link = document.createElement('a');
                link.href = url;
                link.download = item.Name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            // для разработки
            else {                
                let response = await httpAPI({ url, method: 'GET', responseType: 'blob' });

                if (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.Name);
                    document.body.appendChild(link);
                    link.click();
                }
            }
            */
        },        
        async loadAllAttachments() {
            let type = "doc";

            if (this.dataSourceType === 'Order')
                type = "order";

            if (this.dataSourceType === 'Meeting')
                type = "meeting";

            let request = httpAPI({ url: `api/download/attachments?id=${this.documentId}&type=${type}`, method: 'GET', responseType: 'blob', skipErrorHandler: true });
            
            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`Загрузка файла ${name} завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Attachments.zip');
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };

            let asyncReportToastMessage = `Загрузка вложений`;
            this.$notify.async(request, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });


            /*
            // формируем URL
            let url = `api/download/attachments?id=${this.id}&mode=${process.env.NODE_ENV}&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}&type=${type}`

            if (process.env.NODE_ENV === "production") {
                // этот механизм будет работать если хост VUE app и хост API одинаковый
                url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                const link = document.createElement('a');
                link.href = url;
                link.download = "Attachments.zip";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            // для разработки
            else {
                let response = await httpAPI({ url, method: 'GET', responseType: 'blob' });
                
                if (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Attachments.zip');
                    document.body.appendChild(link);
                    link.click();
                }
            }
            */
        },
    },
    created() {
        this.update();
    }
}
</script>