<template>
    <div>
        <v-card flat v-if="dataSource && dataSource.length > 0">
            <v-card-text>
                <div class="form-box-title">{{$t("Связанные_документы")}}</div>
                
                    <v-row 
                        class="full-width-row" 
                        v-for="item in dataSource" 
                        :key="item.type"
                        no-gutters
                    >
                        <v-col cols="12" sm="12" md="2">
                            <label class="f-label">{{ $t(GetLinkedTypes[parseInt(item.type)]) }}</label>
                        </v-col>

                        <v-col  cols="12" sm="12" md="10">
                            <div class="onlyReadData more-per-lab-wrap">
                                <v-doc-chip
                                    v-for="(doc, index) in item.values"
                                    :key="item.type+index"
                                    :id="doc[6]"
                                    :link-id="doc[0]"
                                    :name="$t(GetDocumentTypes[doc[9]]) + ' № ' + doc[2] + ` ${$t('от_время')} ` + doc[3] + (doc[5] ? ' (' + doc[5] + ')' : '')"
                                    :can-delete="doc[7] === 'True' && !$store.getters['actionsource/isDocumentIsArchive']"
                                    :is-linked-document="true"
                                    :source-document-id="parseInt(item.type) === 9 ? doc[10] : id"
                                    :is-common-document="$store.getters['actionsource/isDataSourceCommon']"
                                    :is-direct-link="parseInt(item.type) !== 9"
                                />
                            </div>
                        </v-col>

                    </v-row>
                
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapGetters } from 'vuex';

export default {
    name: "LinkedDocs",
    props: {
        id: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters('documents/references', ['GetLinkedTypes', 'GetDocumentTypes']),
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
        }
    },
    methods: {
        async update() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/tabs/links/${this.$props.id}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
                headers: { 'isCommon': this.$store.getters['actionsource/isDataSourceCommon'] }
            });
            
            if (response) {
                this.dataSource = this.$_(response?.data.Payload.Data)
                    .groupBy('[1]')
                    .map((value, key) => ({ type: key, values: value }))
                    .orderBy(['type'], ['desc'])
                    .value();
            }
        }
    },
    async created() {
        await this.update();
    },
    mounted() {
        this.$eventBus.$on('update-linked_docs', async () => {
            await this.update();
        });

        this.$eventBus.$on('delete-document-link', async linkId => {
            this.$notify.confirm(this.$t('Удалить_связь_документов'),
                async () => {
                    await this.$store.dispatch('global/actionsource/deleteDocumentLink', linkId);
                }
            );
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('update-linked_docs');
        this.$eventBus.$off('delete-document-link');        
    }
}
</script>